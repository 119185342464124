<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      title="Map"
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>

          <b-col md="12">
            <div class="form-map-box" md="12">
                   <gmap-map
                        ref="googleMap"
                        :center="center"
                        :zoom="zoom"
                        :options="{scrollwheel: true}"
                        style="width:1120px;  height: 500px; overflow: auto"
                      >
                        <!-- <GmapCluster :minimumClusterSize=2 :maxZoom=16 :zoomOnClick=true> -->
                          <gmap-marker
                            :key="index"
                            class="markerCricle"
                            v-for="(m, index) in latlongMarkers"
                            :position="m.position"
                            :icon="m.icon"
                            @click="toggleInfoWindow(m, index)"
                            
                          ></gmap-marker>
                        <!-- </GmapCluster> -->
                        <gmap-info-window
                            :options="infoOptions"
                            :position="infoWindowPos"
                            :opened="infoWinOpen"
                            @closeclick="infoWinOpen=false"
                          >
                            <div v-html="infoContent"></div>
                        </gmap-info-window>
                    </gmap-map>

            </div>
          </b-col>

          
        </b-row>

      </div>
      
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { GET_API, POST_API } from "../../../store/actions.type"
import * as VueGoogleMaps from 'vue2-google-maps'
import GmapCluster from "vue2-google-maps/dist/components/cluster";
var moment = require('moment-timezone');

export default {
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    GmapCluster,
    vSelect,
  },
  data() {
    return {
      polling:null,
      infoWinOpen: false,
      items:[],
      marker: [],
      markers: this.marker,
      latlongMarkers:[],
      center: { lat: 0, lng: 0 },
      zoom:12,
      tableColumns : [
              { key: 'full_name', label: 'Driver Name',sortable: false },
      ],
      infoContent: '',
      refreshTime:15000,

      infoWindowPos: {
        lat: 0,
        lng: 0
      },

      currentMidx: null,

      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        }
      },  
    }
  },
  methods : {
    dataList(value = ''){
      
      if (value == '') {

        this.infoWinOpen = false;
      
      }

      return this.$store.dispatch(POST_API, {
           data:{
             
             role:this.$store.getters.currentUser.role,
             om_sites:this.$store.getters.currentUser.om_sites,
           },
           api: '/api/site-map'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
            } else {
                
                this.items = this.$store.getters.getResults.data;
                this.marker = this.items;
                this.updatelatlongMarker(value);
                return this.items;
            }
        });
    },

    updatelatlongMarker(value){
        var markers = [];
        
        this.marker.forEach(function(val,index){
            
            var shape = {
                coords: [0, 0, 60],
                type: 'circle'
            };
            var icon = {

                url: null /*val.profile_thumbnail*/,  //url
                shape:shape,
                scaledSize: {width: 28, height: 28},
                labelOrigin: {x: 16, y: -10},
                
               
                
            };
            markers.push({
                'position':
                    { 'lat':Number(val.lat), 
                     'lng':Number(val.long)
                    },
                //icon:icon,

                //shape:shape

            })
        });


        
        this.latlongMarkers = markers;

        if (value == '') {
          this.center = markers[0].position

          setTimeout(()=>{
              this.center = this.latlongMarkers[0].position
          }, 500);

        }

        
        

        //console.log(this.center,this.latlongMarkers);
    },

    toggleInfoWindow: function (marker, idx) {
      
      this.infoWindowPos = marker.position;
      this.infoContent = this.getInfoWindowContent(idx,this.marker);
      
      if (this.currentMidx == idx) {

        this.infoWinOpen = !this.infoWinOpen;
      }
      
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    getInfoWindowContent: function (idx,detailData) {
        
         //var img = require('@/assets/images/avatars/default.png');

            return (
              `<div class="">
                <div>
                  <div>
                    <div class="m-2" style="color:#000000"> 
                      
                      
                          <b> Project Site Name : ${detailData[idx].site_name}
                          <br>
                          Prefix : ${detailData[idx].prefix} 
                          <br>
                          Attandance : 5/${detailData[idx].manpower}
                          <br>
                          Last Updated : 11-08-2021
                          </b>
                          
                      </div>
                    </div>
                  </div>
                 
                </div>
              </div>`);
      },
      

      getDataItem(){
        this.polling = setInterval(() => {
            this.dataList('interval');
          }, this.refreshTime)
      },

      refreshDataTime(){

        /*return this.$store.dispatch(GET_API, {
           data:{
             
           },
           api: '/api/refresh-time'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
            } else {
                
                this.refreshTime = this.$store.getters.getResults.data;
                this.refreshTime = Number(this.refreshTime) * 1000;
                this.dataList();
                //return this.refreshTime;
            }
        });*/

        this.refreshTime = 15 * 1000;
        this.dataList();

      }
  },
  mounted(){
    this.refreshDataTime();
    /*this.dataList();*/
    this.getDataItem();
  },
  beforeDestroy () {
    
    clearInterval(this.polling)
  }
  
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
